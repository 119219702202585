<template>
	<!-- DESKTOP - TABLET -->
	<div v-if="!isMobile">
		<div v-if="toplabel" class="ml-1 mr-1">
			<v-layout>
				<v-flex xs12>
					<label v-if="getLabel === '$nbsp;'">&nbsp;</label>
					<label v-else :class="getLabelRequiredClass">{{ getLabel }}</label>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex xs12>
					<v-textarea
						v-model="internalModel"
						v-bind="allProps"
						:class="getEditedClass"
						:rules="getRules"
						solo
						outlined
						flat
						single-line
						auto-grow
						@blur="updateValueLazy"
						@input="updateValueRealtime"
						:placeholder="getPlaceholder"
						:error="internalError"
					></v-textarea>
				</v-flex>
			</v-layout>
		</div>
		<div v-else class="ml-1 mr-1">
			<v-layout>
				<v-flex :class="labelColumnStyles ? labelColumnStyles : 'xs12 sm6 md4 xl3'">
					<label :class="getLabelRequiredClass">{{ getLabel }}</label>
				</v-flex>
				<v-flex :class="valueColumnStyles ? valueColumnStyles : 'xs12 sm6 md8 xl9'">
					<v-textarea
						v-model="internalModel"
						v-bind="allProps"
						:class="getEditedClass"
						:rules="getRules"
						solo
						outlined
						flat
						single-line
						auto-grow
						@blur="updateValueLazy"
						@input="updateValueRealtime"
						:placeholder="getPlaceholder"
						:error="internalError"
					></v-textarea>
				</v-flex>
			</v-layout>
		</div>
	</div>
	<!-- MOBILE -->
	<div v-else>
		<v-layout>
			<v-flex xs12>
				<v-textarea
					v-model="internalModel"
					v-bind="allProps"
					:class="getEditedClass"
					:rules="getRules"
					class="v-text-field--mobile"
					flat
					auto-grow
					@blur="updateValueLazy"
					@input="updateValueRealtime"
					:placeholder="getPlaceholder"
					:error="internalError"
				></v-textarea>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>

import PuiFormComponentMixin from 'pui9-components/src/mixins/PuiFormComponentMixin';

export default {
	name: 'PuiTextArea',
	mixins: [PuiFormComponentMixin],
	data: () => ({
		rules: []
	}),
	props: {
		value: {
			type: [String, Number],
			required: false
		},
		realtime: {
			type: Boolean,
			required: false,
			default: false
		},
		placeholder: {
			type: String,
			default: ' ',
			required: false
		},
		noLazyModel: {
			type: Boolean,
			required: false,
			default: false
		},
		labelColumnStyles: {
			type: String,
			required: false
		},
		valueColumnStyles: {
			type: String,
			required: false
		},
		sanitize:{
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		getLabelRequiredClass() {
			return { 'v-label--required': this.required };
		},
		getEditedClass() {
			return { 'v-text-field--edited': this.isEdited };
		},
		getRules() {
			const rules = [...this.rules];
			if (this.required) {
				var func = (value) => !!value || this.requiredMessage;
				var func2 = () => !this.internalError;
				var func3 = () => {
					if (this.internalModel === '' || this.internalModel === null) {
						return this.requiredMessage;
					}
					return true;
				};
				rules.push(func, func2, func3);
			}
			return rules;
		}
	},
	watch: {
		value(val) {
			if (!this.noLazyModel && !this.firstLazyLoad) {
				this.initializeModel(val);
				this.firstLazyLoad = true;
			} else {
				this.internalModel = val;
			}
		}
	},
	created() {
		if (this.noLazyModel) {
			this.firstLazyLoad = true;
		}
		this.initialValue = this.value;
		this.initializeModel(this.value);
	},
	methods: {
		updateValueLazy() {
			this.checkValue();
			if (!this.realtime) {
				if(this.sanitize) this.internalModel = this.$sanitize(this.internalModel);
				if (this.internalModel === '' || this.internalModel === 'null' || this.internalModel === 'undefined') {
					this.internalModel = undefined;
				}
				this.$emit('input', this.internalModel);
			}
		},
		updateValueRealtime() {
			this.checkValue();
			if (this.realtime ) {
				if(this.sanitize) this.internalModel = this.$sanitize(this.internalModel);
				if (this.internalModel === '' || this.internalModel === 'null' || this.internalModel === 'undefined') {
					this.internalModel = undefined;
				}
				this.$emit('input', this.internalModel);
			}
		},
		initializeModel(value) {
			this.internalModel = value;
			if (value !== null && !this.firstLazyLoad) {
				this.firstLazyLoad = true;
			}
		},
		checkValue() {
			this.clearMessages();
			if (this.required) {
				if (this.internalModel === '' || this.internalModel === null) {
					this.showErrorMessage();
				}
			}
		},
		clearMessages() {
			this.internalError = false;
		},
		showErrorMessage() {
			this.internalError = true;
		}
	}
};
</script>
